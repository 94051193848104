import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import Seo from '../../components/SEO';
import Close from '../../svgs/Close';
import ModalContent from '../../components/Modal/Content';
import ModalImagery from '../../components/Modal/Imagery';
import MetaList from './PersonModal/MetaList';
import Layout from '../../layouts/modal';

import * as styles from '../../components/Modal/modal.module.scss';

class PersonTemplate extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.catchEscKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.catchEscKey, false);
  }

  catchEscKey = evt => {
    if (evt.keyCode === 27) {
      this.goBack();
    }
  };

  goBack = () => {
    if (this.props.location.state && this.props.location.state.referrer) {
      return navigate(-1);
    }
    return navigate('/');
  };

  render() {
    const { transition, data } = this.props;
    const { contentfulPerson: person, site } = data;

    const containerStyles = {
      opacity: 0,
      ...(transition ? transition.style : {}),
    };

    return (
          <Layout>
            <div className={[styles.modal, styles.personModal].join(' ')} style={containerStyles}>
              <Seo
                siteData={site}
                defaultTitle={`${person.name} - Emergence`}
                pathName={`/people/${person.slug}`}
              />
              <div className={styles.background} />
              <button aria-label="Go back" className={styles.close} onClick={this.goBack}>
                <Close />
              </button>
              <header className={styles.header}>
                <h1 className={styles.title}>{person.name}</h1>
                <h5 className={styles.subtitle}>{person.title}</h5>
                {person.executiveAssistant && (
                  <div className={styles.assistant}>
                    {person.executiveAssistantEmail ? (
                      <a href={'mailto:' + person.executiveAssistantEmail}>
                        Executive Assistant: {person.executiveAssistant}
                      </a>
                    ) : (
                      <span>Executive Assistant: {person.executiveAssistant}</span>
                    )}
                  </div>
                )}
              </header>
              {person.companies && (
                <div className={styles.meta}>
                  <MetaList companies={person.companies} theme="blue" />
                </div>
              )}
              <div className={styles.content}>
                <ModalContent
                  slug={`/people/${person.slug}`}
                  description={person.description}
                  featuredPress={person.featuredPress}
                  allPress={person.press}
                  featuredThoughts={person.featuredThoughts}
                  allThoughts={person.thought}
                  buttonUrl={person.buttonUrl}
                  buttonText={person.buttonText}
                  twitter={person.twitter}
                  linkedIn={person.linkedIn}
                  theme="blue"
                />
              </div>
              {person.portrait && (
                <div className={styles.imagery}>
                  <ModalImagery headshot={person.portrait} modalType="person" />
                </div>
              )}
            </div>
          </Layout>
    );
  }
}

export default PersonTemplate;

export const pageQuery = graphql`
  query personQuery($id: String!) {
    contentfulPerson(id: { eq: $id }) {
      name
      title
      slug
      thought {
        id
      }
      press {
        id
      }
      executiveAssistant
      executiveAssistantEmail
      ...PersonContent
      ...PersonImagery
      ...PersonMetaList
    }
    ...SiteQuery
  }
`;
