import React from 'react';
import { Link, graphql } from 'gatsby';
import * as PropTypes from 'prop-types';

import * as metaListStyles from '../../../../components/Modal/metaList.module.scss';
import * as styles from './metaList.module.scss';

const MetaList = ({ companies, theme }) => (
  <dl className={[metaListStyles.list, styles[theme]].join(' ')}>
    <div className={styles.item}>
      <dt className={styles.label}>Companies</dt>
      <div className={styles.valueItems}>
        {companies.map((c, i) => {
          return (
            <dd className={styles.value} key={i}>
              <Link to={`/portfolio/${c.slug}`}>{c.name}</Link>
            </dd>
          );
        })}
      </div>
    </div>
  </dl>
);

MetaList.propTypes = {
  companies: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired,
};

export default MetaList;

export const query = graphql`
  fragment PersonMetaList on ContentfulPerson {
    companies {
      name
      slug
    }
  }
`;
